import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '@models/breadcrumb';

import { PageBaseComponent } from 'src/app/base/page-base/page-base.component';

@Component({
  selector: 'cp-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.less']
})
export class QuotesComponent extends PageBaseComponent {
    breadcrumbs: Breadcrumb[] =
        [
            { label: 'Home', route: '/home' },
            { label: 'Quotes', route: '' }
        ];

    protected override detailPageId: string = 'cpQuotes';
}
