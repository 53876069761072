import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { UrlSegment } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { LinkService } from './link.service';
import { MobileLanguages } from '@models/mobile-languages';
import { InternalDomain } from '@models/internal-domain';
import { Language } from '@models/language';
import { environment } from '@environment';
import { GeneralInfo } from '@models/general-info';

declare global {
    interface Window { cdAnalytics: any }
}

declare var clickdimensions: any;
declare var cdScore: any;

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
    static PRIVACY_ALERT: string = 'privacyAlert';
    private baseUrl: string = environment.ApiBase + 'home/';

    // private internalLanguage: Subject<string> = new Subject<string>();
    // languageId$: Observable<string> = this.internalLanguage.asObservable();

    constructor(
        private http: HttpClient,
    ) {}

    getLanguage(): string {
        const language = localStorage.getItem('currentLanguageId');
        return language === null || language === undefined ? 'en-us' : language;
    }

    getLanguageCode(): string {
        const language = localStorage.getItem('currentLanguageCode');
        return language === null || language === undefined ? 'en' : language;
    }

    getLanguages(): Observable<Language[]> {
        return this.http.get<Language[]>(this.baseUrl + 'languages');
    }

    getGeneralInfo(): Observable<GeneralInfo> {
        let params: HttpParams = new HttpParams();
        params = params.append('language', this.getLanguageCode());

        return this.http.get<GeneralInfo>(this.baseUrl + 'general-info', { params: params });
    }

    getDomainList(): Observable<InternalDomain[]> {
        return this.http.get<InternalDomain[]>(this.baseUrl + 'domains');
    }
}
