import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '@models/breadcrumb';

import { PageBaseComponent } from 'src/app/base/page-base/page-base.component';

@Component({
  selector: 'cp-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.less']
})
export class ShipmentDetailsComponent extends PageBaseComponent implements OnInit {
    breadcrumbs: Breadcrumb[] =
        [
            { label: 'Home', route: '/home' },
            { label: 'Shipments', route: '/shipments'},
            { label: 'Shipment Details', route: '' }
        ];

    protected override detailPageId: string = 'cpShipmentDetails';

    // ngOnInit(): void {
    //     this.getDetails('cpShipmentDetails');
    // }
}
