export const environment = {
    production: false,

    ApiBase: "./CustomerPortal.API/",

    ImageLocation: "assets/",
    LocalImageLocation: "assets/",

    DocumentLocation: "assets/documents/",

    System: "BMS"
};
