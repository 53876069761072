import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '@models/breadcrumb';
import { PageBaseComponent } from 'src/app/base/page-base/page-base.component';

@Component({
  selector: 'cp-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.less']
})
export class ItemsComponent  extends PageBaseComponent implements OnInit {
    breadcrumbs: Breadcrumb[] =
        [
            { label: 'Home', route: '/home' },
            { label: 'Items', route: '' }
        ];

    protected override detailPageId: string = 'cpItems';

    // ngOnInit(): void {
    //     this.getDetails('cpItems');
    // }
}
