<div class="display-frame">
    <div class="toolbar-container">
        <div class="toolbar">
            <div class="toolbar-menus">
                <!-- <div class="toolbar-menu-item">
                    <span mat-icon-button (click)="goHome()">
                        <mat-icon style="font-size: 20px;">home</mat-icon>
                    </span>
                </div> -->
                <div *ngIf="isSignedIn && canViewHome" class="toolbar-menu-item">
                    <a routerLink="home">{{this.menu?.HomeTitle}}</a>
                </div>
                <div *ngIf="isAccountSelected && canViewQuotes" class="toolbar-menu-item">
                    <a routerLink="quotes">{{this.menu?.QuotesTitle}}</a>
                </div>
                <div *ngIf="isAccountSelected && canViewOrders" class="toolbar-menu-item">
                    <a routerLink="orders">{{this.menu?.OrdersTitle}}</a>
                </div>
                <div *ngIf="isAccountSelected && canViewOrders" class="toolbar-menu-item">
                    <a routerLink="orderhistory">{{this.menu?.OrderHistoryTitle}}</a>
                </div>
                <div *ngIf="isAccountSelected && canViewShipments" class="toolbar-menu-item">
                    <a routerLink="shipments">{{this.menu?.ShipmentsTitle}}</a>
                </div>
                <div *ngIf="isAccountSelected && canViewInvoices" class="toolbar-menu-item">
                    <a routerLink="invoices">{{this.menu?.InvoicesTitle}}</a>
                </div>
                <div *ngIf="isAccountSelected && canViewItems" class="toolbar-menu-item">
                    <a routerLink="items">{{this.menu?.ItemsTitle}}</a>
                </div>
                <div *ngIf="isSignedIn && canViewUserMaintenance" class="toolbar-menu-item">
                    <a routerLink="users">{{this.menu?.UsersTitle}}</a>
                </div>
            </div>
            <div class="toolbar-login">
                <a *ngIf="!isSignedIn" href="javascript:void(0)" (click)="onSignIn()" routerLink="login">Sign in</a>
                <div *ngIf="isSignedIn" class="toolbar-menu-item">
                    {{currentUser?.Name}}
                </div>
                <div *ngIf="isSignedIn" class="toolbar-menu-item" style="cursor: pointer;">
                    <span (click)="onSignOut()">{{this.menu?.SignOutTitle}}</span>
                </div>
            </div>
            <div style="float: right; width: 175px; line-height: 21px; margin-right: 40px;">
                Language:&nbsp;
                <div style="width: 100px; float: right;">
                    <select [(ngModel)]="currentLanguageId" class="language-select" (change)="setLanguage($event.target)">
                        <option *ngFor="let language of languages" [ngValue]="language.Id">{{language.Description}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>
