import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventPayload, EventType } from '@azure/msal-browser';
import { PermissionService } from '@services/permissions.service';
import { UserService } from '@services/user.service';
import { filter, firstValueFrom, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'cp-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
    isIframe: boolean = window !== window.parent && !window.opener;

    private readonly _destroying$: Subject<void> = new Subject<void>();

    constructor(
        private _authService: MsalService,
        private _permissionService: PermissionService,
        private _userService: UserService,
        private _router: Router,
        private _msalBroadcastService: MsalBroadcastService
    ) { }

    async ngOnInit() {
        if (!this.isIframe) {
            await firstValueFrom(this._authService.initialize()).then();

            const language = localStorage.getItem('currentLanguageId');
            if (language === null || language === undefined) {
                localStorage.setItem('currentLanguageId', navigator.language);
            }

            this._msalBroadcastService.msalSubject$
                .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
                    takeUntil(this._destroying$))
                .subscribe((msg: EventMessage) => {
                    console.log('LOGIN_SUCCESS');
                    const result: AuthenticationResult = msg.payload as AuthenticationResult;
                    this.SetupUser(result);
                });

        // this._globalService.getLanguages().subscribe(
        //     (l: Language[]) => {
        //         this._languageSyncService.setLanguages(l);
        //         let lang: string = localStorage.getItem('currentLanguageId');
        //         if (!lang || lang === '') {
        //             lang = 'en-us';
        //         }
        //         if (lang) {
        //             const currentLanguage: Language = l.find((l: Language) => l.Id === lang);
        //             localStorage.setItem('currentLanguageId', currentLanguage.Id);
        //             localStorage.setItem('currentLanguageCode', currentLanguage.ISO);
        //             this._languageSyncService.setLanguage(currentLanguage);
        //         }
        //     }
        // );

            var user = this._userService.getCurrentUser();
            if (user) {
                var isLoggedIn = await firstValueFrom(this._permissionService.isLoggedIn());
                if (isLoggedIn) {
                    // user is present w/ valid token
                    user.Token = localStorage.getItem('token');
                    this._userService.setCurrentUser(user);
                } else {
                    // user is present w/ invalid token, try to get refreshed token
                    if (await this._userService.isInternalDomain(user.UserId)) {
                        // internal user - try to get refreshed token
                        await this._userService.loginAuthenticatedUser().then();
                    } else {
                        // external user - force login
                        await this._userService.signOutUser();
                        this._router.navigate(['login']);
                    }
                }
            } else {
                // no user
                // this._router.navigate(['login']);
            }
        }
    }

    ngOnDestroy(): void {
        this._destroying$.next(null);
        this._destroying$.complete();
    }

    private async SetupUser(result: AuthenticationResult): Promise<void> {
        console.log('SetupUser start');
        await this._userService.setInternalAccount(result.account, result.idToken);
        console.log('navigate to home');
        this._router.navigate(['home']);
    }
}

