import { Component, ElementRef, EventEmitter, Output, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';

import { environment } from '@environment';
import { FormService } from '@services/form.service';
import { GlobalService } from '@services/global.service';
import { DataQueryDetail } from '@models/data-query-detail';
import { DataQueryResult } from '@models/data-query-result';
import { DataQueryResultRow } from '@models/data-query-result-row';
import { ModalDataQueryConfigs } from '@models/modal-data-query-configs';

class ResultRow {
    label: string;
    value: string;
    alignment: string;
    specialType: string;
    imageWidth: number;
    imageHeight: number;
    name: string;
    width: number;

    linkTarget: string;
    destinationRoute: string;
    destinationRouteParams: any;
}

class LinkTarget {
    route: string;
    params: any;
    streamType: string;
}

@Component({
    selector: 'cp-data-query-results-list',
    templateUrl: './data-query-results-list.component.html',
    styleUrls: ['./data-query-results-list.component.less']
})
export class DataQueryResultsListComponent {
    @Output() refreshPage = new EventEmitter<boolean>();
    @Output() showModalResults: EventEmitter<ModalDataQueryConfigs> = new EventEmitter<ModalDataQueryConfigs>();

    private currencyFormat: Intl.NumberFormat;
    private dateFormat: Intl.DateTimeFormat;
    private timeFormat: Intl.DateTimeFormat;
    private dateTimeFormat: Intl.DateTimeFormat;
    rows: ResultRow[] = [];
    showNoResultsMessage: boolean;
    noResultsText: string;

    imageLocation: string = environment.ImageLocation;
    localImageLocation: string = environment.LocalImageLocation;

    languageCode: string;
    listType: string;

    constructor(
        private globalService: GlobalService,
        private elementRef: ElementRef,
        private formService: FormService,
        private renderer: Renderer2,
        public sanitizer: DomSanitizer
    ) {
        this.currencyFormat = new Intl.NumberFormat(this.globalService.getLanguage(), {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        });

        this.dateFormat = new Intl.DateTimeFormat(this.globalService.getLanguage(), {
            month: "2-digit",
            day: "2-digit",
            year: "numeric"
        });

        this.timeFormat = new Intl.DateTimeFormat(this.globalService.getLanguage(), {
            hour12: true,
            hour: "2-digit",
            minute: "2-digit"
        });

        this.dateTimeFormat = new Intl.DateTimeFormat(this.globalService.getLanguage(), {
            hour12: true,
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit"
        });
    }

    processResultSet(resultSet: DataQueryResult): void {
        this.languageCode = this.globalService.getLanguageCode();
        this.listType = resultSet.ListType;

        let fieldCount: number = 0;
        if (resultSet.QueryResults.length > 0) {
            fieldCount = resultSet.QueryResults[0].FieldValues.length;
        }

        this.showNoResultsMessage = resultSet.ShowNoResultsMessage;
        this.noResultsText = resultSet.NoResultsText;

        for (let i = 0; i < fieldCount; ++i) {
            const row = new ResultRow();
            row.label = resultSet.Details[i].Title;
            row.alignment = resultSet.Details[i].Alignment;
            row.imageWidth = -1;
            row.imageHeight = -1;

            const data: DataQueryResultRow = resultSet.QueryResults[0];

            switch (resultSet.Details[i].DetailType) {
                case "IOString":
                    row.value = data.FieldValues[i];
                    row.alignment = resultSet.Details[i].Alignment;
                    row.name = resultSet.Details[i].ColumnName;
                    row.width = resultSet.Details[i].Width;
                    row.specialType = "iostring";
                    break;
                case "IOStringRequired":
                    row.value = data.FieldValues[i];
                    row.alignment = resultSet.Details[i].Alignment;
                    row.name = resultSet.Details[i].ColumnName;
                    row.specialType = "iostringrequired";
                    row.width = resultSet.Details[i].Width;
                    break;
                case "FieldMoney":
                    row.value = this.currencyFormat.format(data.FieldValues[i]);
                    row.width = resultSet.Details[i].Width;
                    break;
                case "FieldString":
                    if (resultSet.Details[i].ReferenceSortOrder > 0) {
                        const target: LinkTarget = this.parseDestination(data, resultSet.Details, i);
                        if (target.route === "") {
                            row.linkTarget = "";
                        } else {
                            if (target.route.substring(0, 4) === "http") {
                                row.destinationRoute = target.route;
                                row.linkTarget = "anchorUrl";
                            } else if (target.route.substring(0, 11) === "filestream/") {
                                row.destinationRoute = target.params;
                                row.destinationRouteParams = target.route.substring(target.route.indexOf("/") + 1);
                                row.specialType = target.streamType;
                                row.linkTarget = "filestream/string";
                            } else if (target.route.substring(0, 10) === "dataquery/") {
                                row.destinationRoute = "/";
                                row.destinationRouteParams = target.params;
                                row.linkTarget = "dataquery/string";
                            } else {
                                row.destinationRoute = `/${this.languageCode}/${target.route.toLowerCase()}`;
                                row.destinationRouteParams = target.params;
                                row.linkTarget = "anchor";
                            }
                        }
                    }
                    row.width = resultSet.Details[i].Width;
                    row.value = data.FieldValues[i];
                    break;
                case "FieldDate":
                    const d: Date = new Date(data.FieldValues[i]);
                    if (d.getFullYear() > 1900) {
                        row.value = this.dateFormat.format(d);
                    } else {
                        row.value = "";
                    }
                    row.width = resultSet.Details[i].Width;
                    break;
                case "FieldTime":
                    const t: Date = new Date(data.FieldValues[i]);
                    row.value = this.timeFormat.format(t);
                    row.width = resultSet.Details[i].Width;
                    break;
                case "FieldDateTime":
                    const dt: Date = new Date(data.FieldValues[i]);
                    if (dt.getFullYear() > 1900) {
                        row.value = this.dateTimeFormat.format(dt);
                    } else {
                        row.value = "";
                    }
                    row.width = resultSet.Details[i].Width;
                    break;
                case "FieldNumber":
                    row.width = resultSet.Details[i].Width;
                    row.value = data.FieldValues[i];
                    break;
                case "TargetFilestream":
                case "TargetRelativeURL":
                case "TargetURL":
                case "TargetSQLAction":
                    break;
                case "FieldImage":
                    if (resultSet.Details[i].ReferenceSortOrder > 0) {
                        const target: LinkTarget = this.parseDestination(data, resultSet.Details, i);
                        if (target.route === "") {
                            row.linkTarget = "";
                        } else {
                            if (target.route.substring(0, 4) === "http") {
                                row.destinationRoute = target.route;
                                row.linkTarget = "imageUrl";
                            } else if (target.route.substring(0, 11) === "filestream/") {
                                row.destinationRoute = target.params;
                                row.destinationRouteParams = target.route.substring(target.route.indexOf("/") + 1);
                                row.specialType = target.streamType;
                                row.linkTarget = "filestream/image";
                            } else if (target.route.substring(0, 10) === "dataquery/") {
                                row.destinationRoute = "/";
                                row.destinationRouteParams = target.params;
                                row.linkTarget = "dataquery/image";
                            } else {
                                row.destinationRoute = `/${this.languageCode}/${target.route.toLowerCase()}`;
                                row.destinationRouteParams = target.params;
                                row.linkTarget = "image";
                            }
                        }
                    }
                    row.imageWidth = resultSet.Details[i].Width;
                    row.value = data.FieldValues[i];
                    break;
                case "FieldFilestream":
                    row.specialType = resultSet.Details[i].FilestreamType.toLowerCase();
                    row.value = data.FieldValues[i];
                    row.imageWidth = resultSet.Details[i].ImageWidth;
                    row.imageHeight = resultSet.Details[i].ImageHeight;
                    break;
                default:
                    row.value = data.FieldValues[i];
            }

            this.rows.push(row);
        }
    }

    executeDataQuery(dq: string, idx: number): void {
        if (!dq || dq === "") {
            return; // invalid dataquery
        }

        let invalidInput: boolean = false;
        this.rows.forEach((row: ResultRow) => {
            if (row.name && row.name !== "") {
                const field = this.elementRef.nativeElement.querySelector(`#${row.name}_${idx}`);
                if (field) {
                    if (field.required && field.value === "") {
                        invalidInput = true;
                        this.renderer.setStyle(field, "border-color", "red");
                        this.renderer.setStyle(field, "border-style", "solid");
                        return;
                    }
                    this.renderer.setStyle(field, "border-color", "");
                    this.renderer.setStyle(field, "border-style", "");


                    dq = dq.replace("!" + row.name + "!", field.value);
                }
            }
        });

        if (!invalidInput) {
            this.formService.executeDataQuery(dq, {}).subscribe({
                next: () => this.refreshPage.emit(true)
            });
        }
    }

    private parseDestination(data: DataQueryResultRow, columnDefinitions: DataQueryDetail[], sourceIndex: number): LinkTarget {
        const refSortOrder: number = columnDefinitions[sourceIndex].ReferenceSortOrder;
        if (refSortOrder === 0) {
            return null;
        } else {
            for (let i = 0; i < columnDefinitions.length; ++i) {
                if (columnDefinitions[i].SortOrder === refSortOrder) {
                    // if (data.FieldValues[i].startsWith("http")) {
                    if (columnDefinitions[i].DetailType === "TargetRelativeURL") {
                        const target: LinkTarget = this.parseUrl(data.FieldValues[i]);
                        // target.params["dt"] = 1;
                        return target;
                    } else if (columnDefinitions[i].DetailType === "TargetURL") {
                        return this.parseUrl(data.FieldValues[i]);
                    } else if (columnDefinitions[i].DetailType === "TargetSQLAction") {
                        return {
                            route: "dataquery/",
                            params: data.FieldValues[i],
                            streamType: ""
                        };
                    } else {
                        return {
                            route: "filestream/" + columnDefinitions[i].FilestreamType,
                            params: data.FieldValues[i],
                            streamType: columnDefinitions[i].FilestreamTypeId
                        };
                    }
                }
            }
            return null;
        }
    }

    private parseUrl(url: string): LinkTarget {
        let idx: number;

        const linkTarget: LinkTarget = new LinkTarget();

        if (!url || url === '') {
            linkTarget.route = '';
            return linkTarget;
        }

        if (url.startsWith("http")) {
            linkTarget.route = url;
            linkTarget.params = {};
            return linkTarget;
        }

        idx = url.indexOf("?");
        if (idx > 0) {
            linkTarget.route = url.substring(0, idx);
            url = url.substring(idx + 1);
        } else {
            linkTarget.route = url;
            linkTarget.params = {};
            return linkTarget;
        }

        linkTarget.params = {};
        const params: string[] = url.split("&");
        params.forEach((param: string) => {
            idx = param.indexOf("=");
            linkTarget.params[param.substring(0, idx)] = param.substring(idx + 1);
        });

        return linkTarget;
    }

    getFilestream(fileName: string, streamType: string, fileType: string): void {
        this.formService.getFilestream(fileName, fileType, streamType).subscribe({
            next: (response: any) => {
                if (!response) {
                    alert("File does not exist.");
                }

                saveAs(response, fileName);
            },
            error: (error: any) => {
                alert("Error fetching file.");
            }
    });
    }
}
