import { Component } from '@angular/core';
import { Breadcrumb } from '@models/breadcrumb';
import { PageBaseComponent } from 'src/app/base/page-base/page-base.component';

@Component({
  selector: 'cp-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.less']
})
export class OrderHistoryComponent extends PageBaseComponent {
    breadcrumbs: Breadcrumb[] =
        [
            { label: 'Home', route: '/home' },
            { label: 'OrderHistory', route: '' }
        ];

    protected override detailPageId: string = 'cpCustomerOrderHistory';

    // ngOnInit(): void {
    //     this.getDetails('cpSalesOrders');
    // }
}
