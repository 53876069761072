import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, filter, firstValueFrom, lastValueFrom, map } from 'rxjs';

import { IValidatedUser } from '@models/validated-user';
import { CustomerAccount } from '@models/customer-account';
import { UserCustomerAccounts } from '@models/user-customer-accounts';
import { environment } from '@environment';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionRequiredAuthError, PopupRequest, SilentRequest } from '@azure/msal-browser';
import { User } from '@models/user';
import { InternalDomain } from '@models/internal-domain';
import { GlobalService } from './global.service';
import { UserMaintenanceInfo } from '@models/user-maintenance-info';

@Injectable({
  providedIn: 'root'
})
export class UserService {
    private authorizationState: BehaviorSubject<IValidatedUser | null> = new BehaviorSubject(null);
    authorizationState$: Observable<IValidatedUser | null> = this.authorizationState.asObservable();

    private accountState: BehaviorSubject<CustomerAccount | null> = new BehaviorSubject(null);
    accountState$: Observable<CustomerAccount | null> = this.accountState.asObservable();


    private internalToken: string;

    private baseUserUrl: string = environment.ApiBase + "users/";

    // private currentUser: Subject<IValidatedUser> = new Subject<IValidatedUser>();
    // currentUser$: Observable<IValidatedUser> = this.currentUser.asObservable();

    // private currentAccount: Subject<CustomerAccount> = new Subject<CustomerAccount>();
    // currentAccount$: Observable<CustomerAccount> = this.currentAccount.asObservable();

    constructor(
        private _http: HttpClient,
        private _authService: MsalService,
        private _globalService: GlobalService,
        private _authBroadcastService: MsalBroadcastService
    ) { }

    // initializeMsal(): void {
    //     this._authBroadcastService.msalSubject$
    //         .pipe(
    //             filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    //         )
    //         .subscribe((result: EventMessage) => {
    //             console.log(result);
    //             const payload = result.payload as AuthenticationResult;
    //             this._authService.instance.setActiveAccount(payload.account);
    //         });

    // }

    async signOutUser(): Promise<void> {
        console.log('signOutUser start');
        if (this.isAuthenticated()) {
            let currentAccount: AccountInfo = this._authService.instance.getActiveAccount();
            const logoutHint = currentAccount.idTokenClaims.login_hint;
            this._authService.logoutRedirect({
                logoutHint: logoutHint,
                postLogoutRedirectUri: window.location.origin
            });
        }

        this.clearCurrentUser();
        console.log('signOutUser end');
    }

    async getDomainList(): Promise<InternalDomain[]> {
        return firstValueFrom(this._globalService.getDomainList()).then(
            (domainList: InternalDomain[]) => {
                return domainList;
            }
        );
    }

    async isInternalDomain(emailAddress: string): Promise<boolean> {
        let domains: InternalDomain[] = await this.getDomainList();

        emailAddress = emailAddress.toLowerCase();
        const domainList: InternalDomain[] = domains.filter(
            (domain: InternalDomain) => {
                if (emailAddress.endsWith('@' + domain.Id.toLowerCase())) {
                    return domain;
                }
                return null;
            }
        );

        return domainList.length > 0;
    }

    loginUser(data: any): Observable<boolean> {
        const headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

        return this._http.post<IValidatedUser>(this.baseUserUrl + "user-login", data, { headers: headers }).pipe(
            map((result: IValidatedUser) => {
                this.setCurrentUser(result);
                this.authorizationState.next(result);
                return result !== null && result !== undefined;
            })
        );
    }

    async loginInternalUser(userId: string): Promise<void> {
        console.log('loginInternalUser start');
        // HACK because of bug in MSAL where value doesn't get cleared upon sign-out:
        sessionStorage.removeItem('msal.interaction.status');

        let req: PopupRequest = {
            authority: 'https://login.microsoftonline.com/usnr.onmicrosoft.com',
            scopes: [],
            prompt: "login",
            loginHint: userId
        }

        await firstValueFrom(this._authService.loginPopup(req));
        // .then(
        //     (result: AuthenticationResult) => {
        //         this._authService.instance.setActiveAccount(result.account);
        //     });

        // if (this._authService.instance.getActiveAccount() !== null) {
        //     await this.createValidatedUser();
        // }
        // return this._authService.acquireTokenPopup(req).pipe(
        //     map((response: AuthenticationResult) => {
        //         console.log('acquireTokenPopup success');
        //         this._authService.instance.setActiveAccount(response.account);
        //         this.internalToken = response.idToken;

        //         if (this.internalToken && this.internalToken !== '') {
        //             console.log('acquireTokenPopup success/internalToke = ' + this.internalToken);
        //             this.createValidatedUser().then();
        //         }
        //     })
        // );
    }

    async setInternalAccount(account: AccountInfo, token: string): Promise<void> {
        this._authService.instance.setActiveAccount(account);
        this.internalToken = token;
        if (this.internalToken && this.internalToken !== '') {
            await this.createValidatedUser();
        }
    }

    isAuthenticated(): boolean {
        return this._authService.instance.getActiveAccount() !== null;
    }

    async createValidatedUser(): Promise<void> {
        await this.loginAuthenticatedUser();
    }

    getCurrentUser(): IValidatedUser {
        const user: string = localStorage.getItem("currentUser");

        if (user !== null && user !== undefined && user !== "") {
            const validatedUser: IValidatedUser = JSON.parse(user);
            return validatedUser;
        }

        return null;
    }

    async canView(entity: string): Promise<boolean> {
        const params: HttpParams = new HttpParams()
            .append('entity', entity);
        return await lastValueFrom(this._http.get<boolean>(this.baseUserUrl + "view", { params }));
    }

    async canEdit(entity: string): Promise<boolean> {
        const params: HttpParams = new HttpParams()
            .append('entity', entity);
        return await lastValueFrom(this._http.get<boolean>(this.baseUserUrl + "edit", { params }));
    }

    validateCurrentUser(user: IValidatedUser): void {
        console.log('validateCurrentUser start');
        if (user) {
            this._http.get<IValidatedUser>(this.baseUserUrl + "validate-user").subscribe({
                next: (response) => {
                    if (!response) {
                        this.clearCurrentUser();
                        console.log('validateCurrentUser end/success');
                    }
                },
                error: (error: any) => { console.error(error); }
            });
        }
    }

    clearCurrentUser(): void {
        console.log('clearCurrentUser start');
        localStorage.removeItem("currentUser");
        localStorage.removeItem("token");
        localStorage.removeItem("customerAccount");
        this.accountState.next(null);
        this.authorizationState.next(null);
        console.log('clearCurrentUser end');
    }

    public setCurrentUser(user: IValidatedUser): boolean {
        console.log('setCurrentUser start');
        if (!user) {
            this.clearCurrentUser();
            console.log('setCurrentUser end/failure');
            return false;
        }

        const storedUser: string = JSON.stringify({
            UserId: user.UserId,
            Name: user.Name
        });
        localStorage.setItem("currentUser", storedUser);
        localStorage.setItem("token", user.Token);
        this.authorizationState.next(user);
        console.log('setCurrentUser end/success');
        return true;
    }

    async loginAuthenticatedUser(): Promise<boolean> {
        console.log('loginAuthenticatedUser start');
        if (this._authService.instance) {
            var accountInfo: AccountInfo = this._authService.instance.getActiveAccount();
            if (accountInfo) {
                console.log('loginAuthenticatedUser authenticated');
                // await this.getAuthenticationToken().then();
            } else {
                console.log('loginAuthenticatedUser need token');
                await this.getAuthenticationToken();
            }
        }

        console.log('loginAuthenticatedUser internal token: ' + this.internalToken);
        if (this.internalToken && this.internalToken !== '') {
            console.log('loginAuthenticatedUser validate user');
            let authToken: string = this.internalToken;
            const data = { userId: accountInfo.username, token: authToken };

            return await firstValueFrom(this._http.post<IValidatedUser>(this.baseUserUrl + "internal-login", data))
                .then((user: IValidatedUser) => {
                    if (user.Name === "" || user.Name === null) {
                        user.Name = accountInfo.username;
                    }

                    console.log('loginAuthenticatedUser end/success');
                    return this.setCurrentUser(user);
                });
        }

        // localStorage.removeItem("customerAccount");

        console.log('loginAuthenticatedUser end/failure');
        return false;
    }

    private async getAuthenticationToken(): Promise<string> {
        console.log('getAuthenticationToken start');
        this.internalToken = '';
        let req: SilentRequest = {
            authority: 'https://login.microsoftonline.com/usnr.onmicrosoft.com',
            scopes: ['User.Read']
        }
        let self = this;
        await firstValueFrom(this._authService.acquireTokenSilent(req)).then((authResult: AuthenticationResult) => {
            self.internalToken = authResult.idToken;
            console.log('getAuthenticationToken acquireTokenSilent success');
        })
        .catch(async (error) => {
            if (error instanceof InteractionRequiredAuthError) {
                this.signOutUser();
            }
            console.log('getAuthenticationToken acquireTokenSilent failure');
        });

        console.log('getAuthenticationToken end ' + this.internalToken);
        return this.internalToken;
    }

    getCustomerAccountOptions(criteria: string): Observable<UserCustomerAccounts> {
        const params: HttpParams = new HttpParams({ fromObject: { criteria: criteria, userId: '' } });
        return this._http.get<UserCustomerAccounts>(this.baseUserUrl + "customer-accounts", { params: params });
    }

    getCustomerAccountsForUser(userId: string): Observable<UserCustomerAccounts> {
        const params: HttpParams = new HttpParams({ fromObject: { criteria: '', userId: userId } });
        return this._http.get<UserCustomerAccounts>(this.baseUserUrl + "customer-accounts", { params: params });
    }

    setCustomerAccount(account: CustomerAccount, accountPage: string): void {
        localStorage.setItem("customerAccount", JSON.stringify(account));
        localStorage.setItem("customerAccountPage", accountPage);
        this.accountState.next(account);
    }

    getCustomerAccount(): CustomerAccount {
        const customerAccount: string = localStorage.getItem("customerAccount");
        if (customerAccount && customerAccount !== "") {
            return JSON.parse(customerAccount);
        }

        return null;
    }

    getCustomerAccountPage(): string {
        return localStorage.getItem("customerAccountPage");
    }

    getUsers(searchCriteria: string): Observable<User[]> {
        let params: HttpParams = new HttpParams()
            .set('searchCriteria', searchCriteria);

        return this._http.get<User[]>(`${this.baseUserUrl}userlist`, {
            params: params
        });
    }

    async updateUser(editedUser: User): Promise<boolean> {
        const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        return await firstValueFrom(this._http.put(`${this.baseUserUrl}update`, editedUser, { headers: headers})).then();
    }

    async updateUserAccounts(editedUser: User, accounts: CustomerAccount[]): Promise<boolean> {
        const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        const params: HttpParams = new HttpParams()
            .set("userId", editedUser.Id);
        return await firstValueFrom(this._http.put(`${this.baseUserUrl}update/accounts`, accounts, { headers: headers, params: params})).then();
    }

    async addUser(newUser: User): Promise<boolean> {
        return await firstValueFrom(this._http.post(`${this.baseUserUrl}add`, newUser)).then();
    }

    async deleteUser(user: User): Promise<boolean> {
        let params: HttpParams = new HttpParams()
            .set('userId', user.Id);

        if (user.Id == this.getCurrentUser().UserId) {
            alert('Current user cannot be deleted');
        }

        return await firstValueFrom(this._http.delete(`${this.baseUserUrl}delete`, { params: params })).then();
    }

    getUserMaintenanceInfo(): Observable<UserMaintenanceInfo> {
        let params: HttpParams = new HttpParams();
        params = params.append('language', this._globalService.getLanguageCode());

        return this._http.get<UserMaintenanceInfo>(this.baseUserUrl + 'user-maintenance-info', { params: params });

    }
}

export const canViewUserMaintenance =
    (userService = inject(UserService)) => userService.canView("users");

