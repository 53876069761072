import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '@services/user.service';
import { BaseComponent } from '../base/base/base.component';
import { User } from '@models/user';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserEditComponent, UserEditData } from '../user-edit/user-edit.component';
import { firstValueFrom } from 'rxjs';
import { Breadcrumb } from '@models/breadcrumb';
import { UserMaintenanceInfo } from '@models/user-maintenance-info';
import { LanguageSyncService } from '@services/language-sync.service';
import { Language } from '@models/language';

@Component({
  selector: 'cp-user-maintenance',
  templateUrl: './user-maintenance.component.html',
  styleUrls: ['./user-maintenance.component.less']
})
export class UserMaintenanceComponent extends BaseComponent implements OnInit {
    dataSource: MatTableDataSource<User>;
    searchTerm: string = '';
    noResultsFound: boolean = false;

    selectedUserId: string = "";

    displayColumns: string[] = [
        'userId', 'firstName', 'lastName', 'company', 'role', 'enabled'
    ];

    breadcrumbs: Breadcrumb[] =
        [
            { label: 'Home', route: '/home' },
            { label: 'Users', route: '' }
        ];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    userList: User[];

    canEditAdminUsers: boolean = false;

    userMaintenanceInfo: UserMaintenanceInfo;

    constructor(private _userService: UserService, private _dialog: MatDialog, private _languageSyncService: LanguageSyncService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.getUsers('');

        this._languageSyncService.getLanguage().subscribe(
            (_: Language) => this.initialize()
        );

        this.canEditAdminUsers = await this._userService.canEdit('adminusers');
    }

    initialize(): void {
        this._userService.getUserMaintenanceInfo().subscribe((u: UserMaintenanceInfo) => {
            this.userMaintenanceInfo = u;
        }
     );
    }

    isEditable(user: User): boolean {
        return  (user.Role ==='Partner') || (this.canEditAdminUsers);
    }

    private getUsers(searchCriteria: string): void {
        this._userService.getUsers(searchCriteria).subscribe({
            next: (results: User[]) => {
                if (!results || results.length === 0) {
                    this.noResultsFound = true;
                    this.userList = [];
                    this.dataSource = new MatTableDataSource(this.userList);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.paginator.length = 0;
                    return;
                }
                this.userList = results;
                this.dataSource = new MatTableDataSource(this.userList);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.paginator.length = results.length;
            },
            error: (e) => {
                this.noResultsFound = true;
                this.userList = [];
                this.dataSource = new MatTableDataSource(this.userList);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.paginator.length = 0;
        }
        });
    }

    selectUser(user: User): void {
        this.selectedUserId = user.Id;
    }

    addUser(): void {
        let userData: UserEditData = {
            user: null,
            action: 'Add',
            accounts: null
        };

        const addDialogRef: MatDialogRef<UserEditComponent> =
            this._dialog.open(UserEditComponent, { data: userData, width: '500px' });

        addDialogRef.afterClosed().subscribe({ next: () => this.getUsers('') });
    }

    async edituser(user: User, event: MouseEvent): Promise<void> {
        event.stopPropagation();

        if (!this.isEditable(user)) {
            return;
        }

        var accounts = await firstValueFrom(this._userService.getCustomerAccountsForUser(user.Id));
        let userData: UserEditData = {
            user: user,
            action: 'Edit',
            accounts: accounts
        };

        const editDialogRef: MatDialogRef<UserEditComponent> =
            this._dialog.open(UserEditComponent, { data: userData, width: '500px' });

        editDialogRef.afterClosed().subscribe({ next: () => this.getUsers('') });
    }

    onSearch(): void {
        this.noResultsFound = false;
        this.getUsers(this.searchTerm);
    }

}
