import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '@models/breadcrumb';

import { PageBaseComponent } from 'src/app/base/page-base/page-base.component';

@Component({
  selector: 'cp-quote-details',
  templateUrl: './quote-details.component.html',
  styleUrls: ['./quote-details.component.less']
})
export class QuoteDetailsComponent extends PageBaseComponent {
    breadcrumbs: Breadcrumb[] =
        [
            { label: 'Home', route: '/home' },
            { label: 'Quotes', route: '/quotes'},
            { label: 'Quote Details', route: '' }
        ];

    override detailPageId: string = 'cpQuoteDetails';

    // ngOnInit(): void {
    //     this.initialize();
    // }

    // override initialize(): void {
    //     this.getDetails('cpQuoteDetails');
    // }
}
