import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '@models/breadcrumb';
import { PageBaseComponent } from 'src/app/base/page-base/page-base.component';

@Component({
  selector: 'cp-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.less']
})
export class ItemDetailsComponent extends PageBaseComponent implements OnInit {
    breadcrumbs: Breadcrumb[] =
        [
            { label: 'Home', route: '/home' },
            { label: 'Items', route: '/items'},
            { label: 'Item Details', route: '' }
        ];

    protected override detailPageId: string = 'cpItemDetails';

    // ngOnInit(): void {
    //     this.getDetails('cpItemDetails');
    // }
}
