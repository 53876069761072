import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RecaptchaV3Module } from 'ng-recaptcha';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormBaseComponent } from './base/form-base/form-base.component';
import { AnchorComponent } from './data-query/anchor/anchor.component';
import { DataQueryResultsComponent } from './data-query/data-query-results/data-query-results.component';
import { DataQueryResultsHeadingComponent } from './data-query/data-query-results-heading/data-query-results-heading.component';
import { DataQueryResultsGridComponent } from './data-query/data-query-results-grid/data-query-results-grid.component';
import { ModalResultsDialogComponent } from './data-query/modal-results-dialog/modal-results-dialog.component';
import { SortableColumnComponent } from './data-query/sortable-column/sortable-column.component';
import { DataQueryResultsListComponent } from './data-query/data-query-results-list/data-query-results-list.component';
import { ModalComponent } from './modal/modal.component';
import { FormFieldComponent } from './base/form-field/form-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldErrorDisplayComponent } from './base/field-error-display/field-error-display.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { HeaderComponent } from './header/header.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { FooterComponent } from './footer/footer.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { QuotesComponent } from './pages/quotes/quotes.component';
import { ShipmentsComponent } from './pages/shipments/shipments.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { BannerComponent } from './banner/banner.component';
import { FormDetailComponent } from './base/form-detail/form-detail.component';
import { MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, PublicClientApplication, LogLevel } from '@azure/msal-browser';
import { CustomerAccountSelectorComponent } from './pages/customer-account-selector/customer-account-selector.component';
import { BaseComponent } from './base/base/base.component';
import { PageDescriptionHeadingComponent } from './base/page-description-heading/page-description-heading.component';
import { PageDescriptionComponent } from './base/page-description/page-description.component';
import { PageBaseComponent } from './base/page-base/page-base.component';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { InvoiceDetailsComponent } from './pages/invoice-details/invoice-details.component';
import { QuoteDetailsComponent } from './pages/quote-details/quote-details.component';
import { ShipmentDetailsComponent } from './pages/shipment-details/shipment-details.component';
import { PageViewComponent } from './base/page-view/page-view.component';
import { AuthInterceptor } from '@services/auth-interceptor.service';
import { AuthenticationComponent } from './authentication/authentication.component';
import { ItemsComponent } from './pages/items/items.component';
import { ItemDetailsComponent } from './pages/item-details/item-details.component';
import { UserMaintenanceComponent } from './user-maintenance/user-maintenance.component';
import { ImageComponent } from './data-query/image/image.component';
import { ImageLinkComponent } from './data-query/image-link/image-link.component';
import { IOStringComponent } from './data-query/iostring/iostring.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserAccountsComponent } from './user-accounts/user-accounts.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { AccountInfoComponent } from './account-info/account-info.component';
import { TileButtonComponent } from './tile-button/tile-button.component';
import { CustomerSelectionComponent } from './pages/customer-selection/customer-selection.component';
import { AccountDisplayComponent } from './account-display/account-display.component';
import { OrderHistoryComponent } from './pages/order-history/order-history.component';

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
}

@NgModule({
    declarations: [
        AppComponent,
        FormBaseComponent,
        AnchorComponent,
        DataQueryResultsComponent,
        DataQueryResultsHeadingComponent,
        ModalResultsDialogComponent,
        SortableColumnComponent,
        DataQueryResultsListComponent,
        ModalComponent,
        DataQueryResultsGridComponent,
        FormFieldComponent,
        FieldErrorDisplayComponent,
        OrdersComponent,
        HeaderComponent,
        ToolbarComponent,
        FooterComponent,
        InvoicesComponent,
        QuotesComponent,
        ShipmentsComponent,
        LoginComponent,
        HomeComponent,
        BannerComponent,
        FormDetailComponent,
        PageBaseComponent,
        CustomerAccountSelectorComponent,
        BaseComponent,
        PageDescriptionHeadingComponent,
        PageDescriptionComponent,
        OrderDetailsComponent,
        InvoiceDetailsComponent,
        QuoteDetailsComponent,
        ShipmentDetailsComponent,
        PageViewComponent,
        AuthenticationComponent,
        ItemsComponent,
        ItemDetailsComponent,
        UserMaintenanceComponent,
        ImageComponent,
        ImageLinkComponent,
        IOStringComponent,
        UserEditComponent,
        UserAccountsComponent,
        BreadcrumbComponent,
        AccountInfoComponent,
        TileButtonComponent,
        CustomerSelectionComponent,
        AccountDisplayComponent,
        OrderHistoryComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatTreeModule,
        HttpClientModule,
        RecaptchaV3Module,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: '4addd650-fdf6-4f16-becf-b7c6b0aea1f6',
                // clientId: '89de6df0-8462-4106-a6dc-390a8a707145',
                authority: 'https://login.microsoftonline.com/usnr.onmicrosoft.com',
                redirectUri: window.location.origin + '/dtsi',
                postLogoutRedirectUri: window.location.origin
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: true
            },
            system: {
                loggerOptions: {
                    loggerCallback,
                    logLevel: LogLevel.Verbose,
                    piiLoggingEnabled: false
                }
            }
        }),
        {
            interactionType: InteractionType.Popup
        }, null)
    ],
    providers: [
        // {
        //     provide: RECAPTCHA_V3_SITE_KEY,
        //     useValue: "6LdiLUAiAAAAAI-0CNSKOTLLDRWNyyFyy6gqc5yU"
        // },
        MsalService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
