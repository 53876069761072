import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '@models/breadcrumb';

import { PageBaseComponent } from 'src/app/base/page-base/page-base.component';

@Component({
  selector: 'cp-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.less']
})
export class OrderDetailsComponent extends PageBaseComponent implements OnInit {
    breadcrumbs: Breadcrumb[] =
        [
            { label: 'Home', route: '/home' },
            { label: 'Orders', route: '/orders' },
            { label: 'Order Details', route: '' }
        ];

    protected override detailPageId: string = 'cpSalesOrderDetails';

    // ngOnInit(): void {
    //     this.getDetails('cpSalesOrderDetails');
    // }
}
