import { Component } from '@angular/core';

import { PageBaseComponent } from 'src/app/base/page-base/page-base.component';
import { Breadcrumb } from '@models/breadcrumb';

@Component({
    selector: 'cp-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.less']
})
export class OrdersComponent extends PageBaseComponent {
    breadcrumbs: Breadcrumb[] =
        [
            { label: 'Home', route: '/home' },
            { label: 'Orders', route: '' }
        ];

    protected override detailPageId: string = 'cpSalesOrders';

    // ngOnInit(): void {
    //     this.getDetails('cpSalesOrders');
    // }
}
