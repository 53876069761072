import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OrdersComponent } from './pages/orders/orders.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { QuotesComponent } from './pages/quotes/quotes.component';
import { ShipmentsComponent } from './pages/shipments/shipments.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { InvoiceDetailsComponent } from './pages/invoice-details/invoice-details.component';
import { QuoteDetailsComponent } from './pages/quote-details/quote-details.component';
import { ShipmentDetailsComponent } from './pages/shipment-details/shipment-details.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { ItemsComponent } from './pages/items/items.component';
import { ItemDetailsComponent } from './pages/item-details/item-details.component';
import { UserMaintenanceComponent } from './user-maintenance/user-maintenance.component';
import { canViewUserMaintenance } from '@services/user.service';
import { CustomerSelectionComponent } from './pages/customer-selection/customer-selection.component';
import { hasAccountSelected, isLoggedIn } from '@services/can-activate.guard';
import { OrderHistoryComponent } from './pages/order-history/order-history.component';
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [
    { path: 'orders', component: OrdersComponent, canActivate: [ isLoggedIn, hasAccountSelected ] },
    { path: 'orderdetails', component: OrderDetailsComponent, canActivate: [ isLoggedIn, hasAccountSelected ] },
    { path: 'orderhistory', component: OrderHistoryComponent, canActivate: [ isLoggedIn, hasAccountSelected ] },
    { path: 'invoices', component: InvoicesComponent, canActivate: [ isLoggedIn, hasAccountSelected ] },
    { path: 'invoicedetails', component: InvoiceDetailsComponent, canActivate: [ isLoggedIn, hasAccountSelected ] },
    { path: 'quotes', component: QuotesComponent, canActivate: [ isLoggedIn, hasAccountSelected ] },
    { path: 'quotedetails', component: QuoteDetailsComponent, canActivate: [ isLoggedIn, hasAccountSelected ] },
    { path: 'shipments', component: ShipmentsComponent, canActivate: [ isLoggedIn, hasAccountSelected ] },
    { path: 'shipmentdetails', component: ShipmentDetailsComponent, canActivate: [ isLoggedIn, hasAccountSelected ] },
    { path: 'items', component: ItemsComponent, canActivate: [ isLoggedIn, hasAccountSelected ] },
    { path: 'itemdetails', component: ItemDetailsComponent, canActivate: [ isLoggedIn, hasAccountSelected ] },
    { path: 'accounts', component: CustomerSelectionComponent, canActivate: [ isLoggedIn ] },
    { path: 'login', component: LoginComponent },
    { path: 'Authentication', component: AuthenticationComponent },
    { path: 'home', component: HomeComponent, canActivate: [ isLoggedIn ] },
    { path: 'users', component: UserMaintenanceComponent, canActivate: [() => canViewUserMaintenance() ] },
    { path: "dtsi", component: LoginComponent },
    // { path: "dtsi", redirectTo: "login", pathMatch: "full" },
    { path: '**', redirectTo: "/home" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation:
      !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
        ? "enabledNonBlocking"
        : "disabled", // Set to enabledBlocking to use Angular Universal
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
