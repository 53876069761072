import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '@models/breadcrumb';

import { PageBaseComponent } from 'src/app/base/page-base/page-base.component';

@Component({
  selector: 'cp-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.less']
})
export class InvoiceDetailsComponent extends PageBaseComponent implements OnInit {
    breadcrumbs: Breadcrumb[] =
        [
            { label: 'Home', route: '/home' },
            { label: 'Invoices', route: '/invoices' },
            { label: 'Invoice Details', route: '' },
        ];

    protected override detailPageId: string = 'cpInvoiceDetails';

    // ngOnInit(): void {
    //     this.getDetails('cpInvoiceDetails');
    // }
}
