<div class="tile-area">
    <div>
        <div style="display: table">
            <div style="display: table-row; margin: auto; font-size: 50px; width: 100%; text-align: center; font-weight: bold;">
                {{this.generalInfo?.HomePageTitle}}
            </div>
            <div style="display: table-row">
                <div style="display: flex; flex-wrap: wrap; justify-content: center; align-items: center; height: 120px; margin-bottom: 20px;">
                    <cp-tile-button *ngIf="multipleAccountsAvailable" color="white" [backgroundColor]="backgroundColor"
                        [hoverColor]="hoverColor" icon="switch_account" [width]="120"
                        [height]="120" (buttonClick)="gotoAccountSelector()">
                        {{this.generalInfo?.AccountSelectionTitle}}
                    </cp-tile-button>
                    <cp-tile-button color="white" [backgroundColor]="backgroundColor"
                        [hoverColor]="hoverColor" icon="request_quote" [width]="120"
                        [height]="120" (buttonClick)="gotoQuotes()"
                        [disabled]="!isAccountSelected" [disabledBackgroundColor]="disabledBackgroundColor">
                        {{this.generalInfo?.QuotesTitle}}
                    </cp-tile-button>
                    <cp-tile-button *ngIf="isBMS" color="white" [backgroundColor]="backgroundColor"
                        [hoverColor]="hoverColor" icon="shopping_cart" [width]="120"
                        [height]="120" (buttonClick)="gotoOrders()"
                        [disabled]="!isAccountSelected" [disabledBackgroundColor]="disabledBackgroundColor">
                        {{this.generalInfo?.OrdersTitle}}
                    </cp-tile-button>
                    <cp-tile-button *ngIf="isUSNR" color="white" [backgroundColor]="backgroundColor"
                        [hoverColor]="hoverColor" icon="support_agent" [width]="120"
                        [height]="120" (buttonClick)="gotoServiceOverview()"
                        [disabled]="!isAccountSelected" [disabledBackgroundColor]="disabledBackgroundColor">
                        {{this.generalInfo?.ServiceOverviewTitle}}
                    </cp-tile-button>
                    <cp-tile-button color="white" [backgroundColor]="backgroundColor"
                        [hoverColor]="hoverColor" icon="history" [width]="120"
                        [height]="120" (buttonClick)="gotoOrderHistory()"
                        [disabled]="!isAccountSelected" [disabledBackgroundColor]="disabledBackgroundColor">
                        {{this.generalInfo?.OrderHistoryTitle}}
                    </cp-tile-button>
                    <cp-tile-button color="white" [backgroundColor]="backgroundColor"
                        [hoverColor]="hoverColor" icon="local_shipping" [width]="120"
                        [height]="120" (buttonClick)="gotoShipments()"
                        [disabled]="!isAccountSelected" [disabledBackgroundColor]="disabledBackgroundColor">
                        {{this.generalInfo?.ShipmentsTitle}}
                    </cp-tile-button>
                    <cp-tile-button color="white" [backgroundColor]="backgroundColor"
                        [hoverColor]="hoverColor" icon="receipt" [width]="120"
                        [height]="120" (buttonClick)="gotoInvoices()"
                        [disabled]="!isAccountSelected" [disabledBackgroundColor]="disabledBackgroundColor">
                        {{this.generalInfo?.InvoicesTitle}}
                    </cp-tile-button>
                    <cp-tile-button color="white" [backgroundColor]="backgroundColor"
                        [hoverColor]="hoverColor" icon="construction" [width]="120"
                        [height]="120" (buttonClick)="gotoItems()"
                        [disabled]="!isAccountSelected" [disabledBackgroundColor]="disabledBackgroundColor">
                        {{this.generalInfo?.ItemsTitle}}
                    </cp-tile-button>
                </div>
                <div style="height: 90px; background-color: #e6e6e6; width: 100%;">
                    <table style="height: 90px;" width="100%">
                        <tr>
                            <td style="height: 90px;" rowspan="4">
                                <div style="position: relative; width: 90px; height: 90px;">
                                    <mat-icon style="color: rgba(99, 99, 99, 0.3); height: 80px; line-height: 80px; width: 80px; font-size: 80px; position: absolute; bottom: 0; left: 0;">factory</mat-icon>
                                </div>
                            </td>
                            <td style="width: 100%">
                                <div style="display: inline-block; color: #999999;">
                                    <cp-account-display></cp-account-display>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="tile-area">
    <div>
        <div class="tile-buttons">
            <cp-tile-button *ngIf="multipleAccountsAvailable" color="white" [backgroundColor]="backgroundColor"
                [hoverColor]="hoverColor" icon="switch_account" [width]="120"
                [height]="120" (buttonClick)="gotoAccountSelector()">
                Account Selection
            </cp-tile-button>
            <cp-tile-button color="white" [backgroundColor]="backgroundColor"
                [hoverColor]="hoverColor" icon="request_quote" [width]="120"
                [height]="120" (buttonClick)="gotoQuotes()">
                Quotes
            </cp-tile-button>
            <cp-tile-button color="white" [backgroundColor]="backgroundColor"
                [hoverColor]="hoverColor" icon="shopping_cart" [width]="120"
                [height]="120" (buttonClick)="gotoOrders()">
                Orders
            </cp-tile-button>
            <cp-tile-button color="white" [backgroundColor]="backgroundColor"
                [hoverColor]="hoverColor" icon="local_shipping" [width]="120"
                [height]="120" (buttonClick)="gotoShipments()">
                Shipments
            </cp-tile-button>
            <cp-tile-button color="white" [backgroundColor]="backgroundColor"
                [hoverColor]="hoverColor" icon="receipt" [width]="120"
                [height]="120" (buttonClick)="gotoInvoices()">
                Invoices
            </cp-tile-button>
            <cp-tile-button color="white" [backgroundColor]="backgroundColor"
                [hoverColor]="hoverColor" icon="construction" [width]="120"
                [height]="120" (buttonClick)="gotoItems()">
                Items
            </cp-tile-button>
        </div>
    </div>
</div> -->
