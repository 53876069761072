import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PageBaseComponent } from '../base/page-base/page-base.component';
import { UserService } from '@services/user.service';
import { UserCustomerAccounts } from '@models/user-customer-accounts';
import { CustomerAccount } from '@models/customer-account';
import { GlobalService } from '@services/global.service';
import { GeneralInfo } from '@models/general-info';
import { environment } from '@environment';

@Component({
  selector: 'cp-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent extends PageBaseComponent implements OnInit {
    backgroundColor: string = 'rgb(170, 0, 0)';
    hoverColor: string = 'rgb(136, 0, 0)';
    disabledBackgroundColor: string = 'rgba(170, 0, 0, 0.5)';

    multipleAccountsAvailable: boolean = false;

    currentAccount: CustomerAccount;
    generalInfo: GeneralInfo;

    get isAccountSelected(): boolean {
        let x: boolean = this.currentAccount !== null && this.currentAccount !== undefined;
        return this.currentAccount !== null && this.currentAccount !== undefined;
    }

    get isUSNR(): boolean {
        return environment.System.toLowerCase() === "usnr";
    }

    get isBMS(): boolean {
        return environment.System.toLowerCase() === "bms";
    }

    constructor(
        private _router: Router,
        private _userService: UserService,
        private _globalService: GlobalService
    ) {
        super();
    }

    override ngOnInit(): void {
        this._userService.getCustomerAccountOptions('').subscribe(
            (accounts: UserCustomerAccounts) => {
                this.multipleAccountsAvailable = accounts.Accounts.length > 1;
            }
        );

        this.currentAccount = this._userService.getCustomerAccount();

        this.initialize();
    }

    protected override initialize(): void {
        this._globalService.getGeneralInfo().subscribe(
            (response: GeneralInfo) => {
                this.generalInfo = response;
            }
        );
    }


    gotoOrders(): void {
        this._router.navigate(['orders']);
    }

    gotoOrderHistory(): void {
        this._router.navigate(['orderhistory']);
    }

    gotoInvoices(): void {
        this._router.navigate(['invoices']);
    }

    gotoQuotes(): void {
        this._router.navigate(['quotes']);
    }

    gotoShipments(): void {
        this._router.navigate(['shipments']);
    }

    gotoItems(): void {
        this._router.navigate(['items']);
    }

    gotoAccountSelector(): void {
        this._router.navigate(['accounts']);
    }

    gotoServiceOverview(): void {
        this._router.navigate(['serviceoverview'])
    }
}
