<div style="flex: 1 0 auto">
    <cp-banner></cp-banner>
    <div class="sticky">
        <cp-toolbar></cp-toolbar>
    </div>
    <cp-account-info></cp-account-info>
    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <router-outlet *ngIf="!isIframe"></router-outlet>
</div>
<div style="flex-shrink: 0">
    <cp-footer></cp-footer>
</div>
