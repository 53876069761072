import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralInfo } from '@models/general-info';
import { Language } from '@models/language';
import { IValidatedUser } from '@models/validated-user';
import { GlobalService } from '@services/global.service';
import { LanguageSyncService } from '@services/language-sync.service';
import { PermissionService } from '@services/permissions.service';
import { UserService } from '@services/user.service';
import { Subscription, firstValueFrom } from 'rxjs';

@Component({
  selector: 'cp-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.less']
})
export class ToolbarComponent implements OnInit, OnDestroy {
    private _userSubscription: Subscription;

    currentUser: IValidatedUser = null;

    isSignedIn: boolean = false;
    isAccountSelected: boolean = false;
    canViewHome: boolean = false;
    canViewUserMaintenance: boolean = false;
    canViewOrders: boolean = false;
    canViewQuotes: boolean = false;
    canViewInvoices: boolean = false;
    canViewShipments: boolean = false;
    canViewItems: boolean = false;

    menu: GeneralInfo;

    languages: Language[] = [];

    currentLanguage: Language;
    currentLanguageId: string;

    constructor(
        private _router: Router,
        private _userService: UserService,
        private _permissionService: PermissionService,
        private _globalService: GlobalService,
        private _languageSyncService: LanguageSyncService
    ) {
        this._userSubscription = this._userService.accountState$.subscribe({
            next: () => this.ngOnInit()
        });

        this._userSubscription = this._userService.authorizationState$.subscribe({
            next: () => this.ngOnInit()
        });
    }

    ngOnInit(): void {
        this.currentUser = this._userService.getCurrentUser();
        this.getUserPermissions();

        this._languageSyncService.getLanguage().subscribe(
            (l: Language) => {
                this.currentLanguage = l;
                this.currentLanguageId = l.Id;
                this.initialize();
            }
        );

        this._globalService.getLanguages().subscribe(
            (l: Language[]) => {
                this._languageSyncService.setLanguages(l);
                this.languages = l;
                let lang: string = localStorage.getItem('currentLanguageId');
                if (!lang || lang === '') {
                    lang = 'en-us';
                }
                if (lang) {
                    lang = lang.toLowerCase();
                    this.currentLanguage = this.languages.find((l: Language) => l.Id.toLowerCase() === lang);
                    if (!this.currentLanguage) {
                        this.currentLanguageId = this.currentLanguage.Id;
                        localStorage.setItem('currentLanguageId', this.currentLanguage.Id);
                        localStorage.setItem('currentLanguageCode', this.currentLanguage.ISO);
                    } else {
                        localStorage.setItem('currentLanguageId', 'en-us');
                        localStorage.setItem('currentLanguageCode', 'en');
                    }
                    this._languageSyncService.setLanguage(this.currentLanguage);
                }
            }
        );

        this.initialize();
    }

    initialize(): void {
        this._globalService.getGeneralInfo().subscribe(
            (response: GeneralInfo) => {
                this.menu = response;
            }
        )
    }

    setLanguage(_: any): void {
        const l = this.languages.find((lang: Language) => lang.Id === this.currentLanguageId);
        localStorage.setItem('currentLanguageId', l.Id);
        localStorage.setItem('currentLanguageCode', l.ISO);
        this._languageSyncService.setLanguage(l);
    }

    private async getUserPermissions(): Promise<void> {
        this.isSignedIn = await firstValueFrom(this._permissionService.isLoggedIn());
        this.isAccountSelected = this.isSignedIn && this._permissionService.isAccountSelected();
        if (this.isSignedIn) {
            if (this.isAccountSelected) {
                this.canViewOrders = await this._userService.canView("orders");
                this.canViewInvoices = await this._userService.canView("invoices");
                this.canViewQuotes = await this._userService.canView("quotes");
                this.canViewItems = await this._userService.canView("items");
                this.canViewShipments = await this._userService.canView("shipments");
            }
            this.canViewUserMaintenance = await this._userService.canView("users");
            this.canViewHome = true;
        } else {
            this.canViewUserMaintenance = false;
            this.canViewOrders = false;
            this.canViewInvoices = false;
            this.canViewQuotes = false;
            this.canViewItems = false;
            this.canViewShipments = false;
            this.canViewHome = false;
        }
    }

    ngOnDestroy(): void {
        this._userSubscription.unsubscribe();
    }

    onSignIn(): void {
        this._router.navigate(['login']);
    }

    onSignOut(): void {
        this._userService.signOutUser();
        this._router.navigate(['login']);
    }

    goHome(): void {
        this._router.navigate(['home']);
    }
}
